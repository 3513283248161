import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import VueAxios from 'vue-axios'
import { config } from '../config';


Vue.use(Vuex)
Vue.use(VueAxios, axios)


Vue.use(Vuex)




const getDefaultState = () => {



  return {
	//vars
	aktiverBereich: '',
	aktiveHK: null,
	aktiveGK: null,
	//title: 'Logbuch Anästhesiologie',
	aktiveEPA : null,
	filterwbjahr: true,
	overlayGKAddEditVisible : false,
	overlayHKAddEditVisible : false,
	editGK : {id : '', bezeichnung : ''},
	editHK : {id : '', bezeichnung : ''},
	overlayInhalteGKAddEditVisible: false,
	overlayInhalteHKAddEditVisible: false,
	overlayEditUserDetailsVisible: false,
	overlayEPAEditVisible: false,
	editEPA: null,
	overlayEditPasswordVisible: false,
	editUser: null,
	editInhalteGK: {id: '', frage: ''},
	editInhalteHK: {id: '', frage: ''},
	searchString : '',
	dopslidervalues: [],
	kppvalues: [],
	
            

	//User
	authenfiziert: false,
	aktiverNutzer: {},
	breadcrumbItems : [],
	vomNutzerimLogbuchGespeichertePruefungen: [],
	ausbilderUnterschrift: '',
	popoverausbilderUnterschrift: false,
	adminList: [],
	
	UnterschriftPendantListe: [],
	
	//Content
	bereiche: [],
	epas: [],
	gks: [],
	smps: [],
	hks : [],
	hksInhalte : [],
	
	//uploadedImage
	pathNewUploadedImage : '',
	
	
	
	steps: [
	{bezeichnung: "1", nr: 1, beschreibung:"Sie dürfen beobachten, aber APT am Patienten noch nicht anwenden", beschreibung1:"Sie dürfen beobachten, aber APT am Patienten noch nicht anwenden", beschreibung2:""},
	{bezeichnung: "2a", nr: 2, beschreibung:"Sie dürfen APT unter direkter Supervision (im Raum) durchführen und gemeinsam mit einer Ärztin/einem Arzt ausführen.",beschreibung1:"Sie dürfen APT unter direkter Supervision (im Raum) durchführen",beschreibung2:"und gemeinsam mit einer Ärztin/einem Arzt ausführen."},
	{bezeichnung: "2b", nr: 3, beschreibung:"Sie dürfen APT unter direkter Supervision (im Raum) durchführen und unter Beobachtung einer Ärztin/eines Arztes ausführen.",beschreibung1:"Sie dürfen APT unter direkter Supervision (im Raum) durchführen",beschreibung2:"und unter Beobachtung einer Ärztin/eines Arztes ausführen."},
	{bezeichnung: "3a", nr: 4, beschreibung:" Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen - eigenständig ausführen, wird umfassend nachgeprüft.", beschreibung1:" Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen", beschreibung2:"und eigenständig ausführen, wird umfassend nachgeprüft."},
	{bezeichnung: "3b", nr: 5, beschreibung:" Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen - eigenständig ausführen und Wichtiges wird nachgeprüft.",beschreibung1:" Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen",beschreibung2:" und eigenständig ausführen und Wichtiges wird nachgeprüft."},
	{bezeichnung: "3c", nr: 6, beschreibung:" Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen - eigenständig ausführen und Wichtiges wird durchgesprochen und ggf. punktuell nachgeprüft.",beschreibung1:" Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen und eigen",beschreibung2:"-ständig ausführen und Wichtiges wird durchgesprochen und ggf. punktuell nachgeprüft."}],


  };
};




export default new Vuex.Store({
  strict: true,
  namespaced: true,
  state: getDefaultState(),
    getters: {
    
    
    sectionGetter: (state) => (forEPA) => {        
        var sections = [];
         // GK 
        //state => {return ;
        var gkssection = [];
		gkssection.push(state.gks.filter(item => item.in_epa == forEPA));
		gkssection.push(state.gks.filter(item => (item.querlink_epa.split(",").includes(forEPA))));
		gkssection = gkssection[0].concat(gkssection[1]);
		
        for (let i = 0; i <  gkssection.length; i++) {
        var anzahlImLogbuch = 0
        if (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 1).length != 0) {
        anzahlImLogbuch =  (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 1).filter(item => item.pruefungsid == gkssection[i].gk_id).length) 
        }
        //alert(anzahlImLogbuch);
         var qltextgk = '';
        
        if (i >= state.gks.filter(item => item.in_epa == forEPA).length) {
        qltextgk = 'QL ';
        }
        
        
        var colorSection = "#808080";
        if (anzahlImLogbuch > 0) {
        colorSection = "#395f73";
        }
        if (i != gkssection.length-1 ) {
           sections.push( { label: qltextgk+gkssection[i].art+' # '+gkssection[i].bezeichnung, label2:anzahlImLogbuch+" Einträge", value: parseInt(500/gkssection.length), color: colorSection, gkhk: 1, item: gkssection[i]});
         }
         else {
                    sections.push( { label: qltextgk+gkssection[i].art+' # '+gkssection[i].bezeichnung, label2:anzahlImLogbuch+" Einträge", value: parseInt(500 - ((i) * parseInt(500/gkssection.length))), color: colorSection, gkhk: 1, item: gkssection[i] });
         }
         }

      
        sections.push( { label: '', label2: '', value: 0 , color: '#dedede', gkhk: 1, item: [] });

        //alert (JSON.stringify(sections));
        // HK 
        //state.hks.filter(item => item.in_epa == forEPA)
        
        var hkssection = [];
		hkssection.push(state.hks.filter(item => item.in_epa == forEPA));
		hkssection.push(state.hks.filter(item => (item.querlink_epa.split(",").includes(forEPA))));
		hkssection = hkssection[0].concat(hkssection[1]);
        for (let i = 0; i <  hkssection.length; i++) {
        var hkanzahlImLogbuch = 0
        var keLabel = "0 /"+hkssection[i].ke3a+" bis KE3a";
        if (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 2).length != 0) {
        hkanzahlImLogbuch =  (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 2).filter(item => item.pruefungsid == hkssection[i].hk_id).length) 
        }
        
        var qltext = '';
        
        if (i >= state.hks.filter(item => item.in_epa == forEPA).length) {
        qltext = 'QL ';
        }
        
        var hkcolorSection = "#808080";
        if (hkanzahlImLogbuch < hkssection[i].ke3a && hkanzahlImLogbuch > 0) {
        hkcolorSection = "#adc8d7";
        keLabel = hkanzahlImLogbuch+"/"+hkssection[i].ke3a+" bis KE3a"
        }
        if (hkanzahlImLogbuch > hkssection[i].ke3a) {
        hkcolorSection = "#7394A5";
        keLabel = hkanzahlImLogbuch+"/"+hkssection[i].ke3b+" bis KE3b"
        }
        if (hkanzahlImLogbuch > hkssection[i].ke3b) {
        hkcolorSection = "#395f73";
        keLabel = hkanzahlImLogbuch+" Einträge. KE3b erreicht"
        }
        if (i != hkssection.length-1 ) {
           sections.push( { label:  qltext+hkssection[i].art+ ' # '+ hkssection[i].bezeichnung, label2:keLabel, value: parseInt(500/hkssection.length), color: hkcolorSection, gkhk: 2, item: hkssection[i] });
         }
         else {
                    sections.push( { label:  qltext+ hkssection[i].art+ ' # '+ hkssection[i].bezeichnung, label2:keLabel, value: parseInt(500 - ((i) * parseInt(500/hkssection.length))), color: hkcolorSection, gkhk: 2, item: hkssection[i] });
         }
         }
        
        return sections;
        
      },
      
            
      wievieleDieserGKImLogbuch: (state) => (forGK) => {
        var anzahlImLogbuch = 0;
        if (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 1).length != 0) {
        anzahlImLogbuch =  (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 1).filter(item => item.pruefungsid == forGK).length) 
        }
        return anzahlImLogbuch;
        },
        
        
       dieseGKImLogbuch: (state) => (forGK) => {
        if (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 1).length != 0) {
        return (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 1).filter(item => item.pruefungsid == forGK)) 
        }
        return [];
        },
        
    wievieleDieserHKImLogbuch: (state) => (forHK) => {
        var anzahlImLogbuch = 0;
        if (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 2).length != 0) {
        anzahlImLogbuch =  (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 2).filter(item => item.pruefungsid == forHK).length) 
        }
        return anzahlImLogbuch;
        },
      
       dieseHKImLogbuch: (state) => (forHK) => {
        if (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 2).length != 0) {
        return (state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.gkhk == 2).filter(item => item.pruefungsid == forHK)) 
        }
        return [];
        },
      
        
        getvomNutzerimLogbuchGespeichertePruefungen: state => { return state.vomNutzerimLogbuchGespeichertePruefungen;},
        getvomNutzerimLogbuchGespeicherteOffenePruefungen: state => { return state.vomNutzerimLogbuchGespeichertePruefungen.filter(item => item.erledigt == 0);},

		getadminList: state => { return state.adminList;},
		
		getAuthentifikation: state => { return state.authenfiziert;},
		getNutzer: state => { return state.aktiverNutzer;},
		getaktiverBereich: state => { return state.aktiverBereich;},
		getBereiche: state => { return state.bereiche;},
		geteditMode: state => {return (state.aktiverNutzer.editorrecht == 1) ? 1 : 0;},
		//getUser: state => {return state.user;},
		getEPAs: state => { return state.epas.filter(item => item.Opa == 0);},
		getOPAs: state => { return state.epas.filter(item => item.Opa == 1);},
		getEPAsOPAs: state => { return state.epas;},
		aktiveEPAID :  state => {return state.aktiveEPA;},
		aktiveEPAIDObject :  state => {return state.epas[state.aktiveEPA-1];},
		
		getUnterschriftPendantListe: state => { return state.UnterschriftPendantListe;},

		
		returnEPAIDObjectfromID : (state) => (idvar) => {return state.epas.filter(item => item.id == idvar)[0];},

		
		returnEPAZugeordneteHK : state => { 
		var inepasundquerlinks = [];
		inepasundquerlinks.push(state.hks.filter(item => item.in_epa == state.aktiveEPA));
		inepasundquerlinks.push(state.hks.filter(item => (item.querlink_epa.split(",").includes(state.aktiveEPA))));
		return inepasundquerlinks;
		},
		
		returnEPAZugeordneteGK : state => {
		var inepasundquerlinks = [];
		inepasundquerlinks.push(state.gks.filter(item => item.in_epa == state.aktiveEPA));
		inepasundquerlinks.push(state.gks.filter(item => (item.querlink_epa.split(",").includes(state.aktiveEPA))));
		return inepasundquerlinks;
		},
		
		returnGKWithID: (state) => (gkid) => {
		var inepasundquerlinks = [];
		inepasundquerlinks.push(state.gks.filter(item => item.gk_id == gkid));
		return inepasundquerlinks;
		
		},
		
		
		returnEPAZugeordneteHKfunktion : (state) => (epaid) => {
		var inepasundquerlinks = [];
		inepasundquerlinks.push(state.hks.filter(item => item.in_epa == epaid));
		inepasundquerlinks.push(state.hks.filter(item => (item.querlink_epa.split(",").includes(epaid))));
		return inepasundquerlinks;
		
		},
		
		
		returnEPAZugeordneteGKfunktion : (state) => (epaid) => {
		var inepasundquerlinks = [];
		inepasundquerlinks.push(state.gks.filter(item => item.in_epa == epaid));
		inepasundquerlinks.push(state.gks.filter(item => (item.querlink_epa.split(",").includes(epaid))));
		return inepasundquerlinks;		},
		
		
		
		returnSucheGK : state => {
		return state.gks
		.filter(item => (item.art.toLowerCase()+' # '+item.bezeichnung.toLowerCase()+item.beschreibung.toLowerCase()+item.titel.toLowerCase()).includes(state.searchString.toLowerCase()))
		;},
		returnSucheHK : state => {
		return state.hks
		.filter(item => (item.art.toLowerCase()+' # '+item.bezeichnung.toLowerCase()+item.beschreibung.toLowerCase()+item.titel.toLowerCase()).includes(state.searchString.toLowerCase()))
		;},
		returnSucheGKItems: state => {
		return state.smps
		.filter(item => (item.frage.toLowerCase()+item.antwort.toLowerCase()).includes(state.searchString.toLowerCase()))
		;},
		returnSMPInhalte : state => {return state.smps.filter(item => item.gk_id == state.aktiveGK.gk_id);},
		returnDOPs : state => {return state.pruefungsstruktur.filter(item => item.hk_id == state.aktiveHK.hk_id);},
		getaktiveGK: state => {return state.gks.filter(item => item.gk_id == state.aktiveGK.gk_id)[0];},
		returnHK: state => {return state.hks.filter(item => item.hk_id == state.aktiveHK);},
		returnhksInhalte: state => {return state.hksInhalte.filter(item => item.hk_id == state.aktiveHK.hk_id);},
		getoverlayGKAddEditVisible: state => {return state.overlayGKAddEditVisible;},
		getoverlayEditPasswordVisible: state => {return state.overlayEditPasswordVisible;},
		getoverlayEditUserDetailsVisible: state => {return state.overlayEditUserDetailsVisible;},
		geteditUser: state => {return state.editUser;},
		geteditGK: state => {return state.editGK;},
		getoverlayHKAddEditVisible: state => {return state.overlayHKAddEditVisible;},
		geteditHK: state => {return state.editHK;},
		getoverlayInhalteGKAddEditVisible: state => {return state.overlayInhalteGKAddEditVisible;},
		getoverlayInhalteHKAddEditVisible: state => {return state.overlayInhalteHKAddEditVisible;},
		geteditInhalteGK: state => {return state.editInhalteGK;},
		geteditInhalteHK: state => {return state.editInhalteHK;},
		getbreadcrumbItems: state => {return state.breadcrumbItems;},
		getaktiveHK: state => {return state.aktiveHK;},
		getSteps: state => {return state.steps;},
		getpathNewUploadedImage: state => {return state.pathNewUploadedImage;},


        getoverlayEPAEditVisible: state => {return state.overlayEPAEditVisible;},
        geteditEPA: state => {return state.editEPA;},


		getfilterwbjahr: state => {return state.filterwbjahr;},
		returnbereichinfosfromString:  (state) => (bereich) => {
		return state.bereiche.filter(item => item.name == bereich);},
		getausbilderUnterschrift: state => {return state.ausbilderUnterschrift;},
		getpopoverausbilderUnterschrift: state => {return state.popoverausbilderUnterschrift;},
		getdopslidervalues: state => {return state.dopslidervalues;},
		getkppvalues: state => {return state.kppvalues;},
		getRotationen: state => {return state.bereiche.map(x => x.name);},
        returnPruefungsnameFromPruefung : (state) => (pruefung) => {
        if (pruefung.gkhk == 2) {
        return state.hks.filter(item => item.hk_id == pruefung.pruefungsid) 
        }
        return state.gks.filter(item => item.gk_id == pruefung.pruefungsid) 
        },



		getclusterepas: state => {
		if (state.filterwbjahr) {
		return state.epas.filter(item => item.zugeordnete_bereiche.toLowerCase().includes(state.aktiverNutzer.rotation.toLowerCase())).filter(item => item.wb_jahr == state.aktiverNutzer.wbjahr);
		}
		return state.epas.filter(item => item.zugeordnete_bereiche.toLowerCase().includes(state.aktiverNutzer.rotation.toLowerCase()));},
	
		

     
     getallclusterepasuebersicht: state => {
		return state.epas.filter(item => item.zugeordnete_bereiche.toLowerCase().includes(state.aktiverBereich.name.toLowerCase()));},
     
     getallclusterepas: state => {
		return state.epas.filter(item => item.zugeordnete_bereiche.toLowerCase().includes(state.aktiverNutzer.rotation.toLowerCase()));},
     
     getallclusterepasAufsicht: state => {
     if (state.aktiverBereich != "") {
		return state.epas.filter(item => item.zugeordnete_bereiche.toLowerCase().includes(state.aktiverBereich.name.toLowerCase()));
		}
		return [];
		
		},
     },
     
     
     
     
    mutations: {
        setBereiche(state, payload) {state.bereiche = payload.payload},
        setEPAs (state, payload) {state.epas = payload.payload},
        setGKs (state, payload) {state.gks = payload.payload},
        seteditGK (state, payload) {state.editGK = payload.payload},
        setHKs (state, payload) {state.hks = payload.payload},
        setadminList(state, payload) {state.adminList = payload.payload},
        seteditHK (state, payload) {state.editHK = payload.payload},
        setaktiveEPA (state, value) {state.aktiveEPA = value.value},
        setoverlayGKAddEditVisible (state, value) {state.overlayGKAddEditVisible = value.value},
        setoverlayHKAddEditVisible (state, value) {state.overlayHKAddEditVisible = value.value},
        setSMPs (state, payload) {state.smps = payload.payload;},
        sethksInhalte (state, payload) {state.hksInhalte = payload.payload;},
        seteditInhalteGK(state, payload) {state.editInhalteGK = payload.payload},
        seteditInhalteHK(state, payload) {state.editInhalteHK = payload.payload},
        
        setoverlayEPAEditVisible(state, value) {state.overlayEPAEditVisible = value.value},
        seteditEPA(state, payload) {state.editEPA = payload.payload},
        
        setoverlayInhalteGKAddEditVisible(state, value) {state.overlayInhalteGKAddEditVisible = value.value},
        setoverlayInhalteHKAddEditVisible(state, value) {state.overlayInhalteHKAddEditVisible = value.value},
        setoverlayEditUserDetailsVisible(state, value) {state.overlayEditUserDetailsVisible = value.value},
        setoverlayEditPasswordVisible(state, value) {state.overlayEditPasswordVisible = value.value},
        seteditUser(state, payload) {state.editUser = payload.payload},
        setfilterwbjahr(state, value) {state.filterwbjahr = value.value},
        setsearchString(state, value) {state.searchString = value},
        setUnterschriftPendantListe(state, value) {state.UnterschriftPendantListe = value}, //Um Autocomplete Nach vollen Namen filtern zu können
        setaktiverBereich(state, value) {state.aktiverBereich = value.value},
        setAuthentifikation(state, value) {state.authenfiziert = value.payload},
        setNutzer(state, value) {state.aktiverNutzer = value.payload},
        setvomNutzerimLogbuchGespeichertePruefungen(state, value) {state.vomNutzerimLogbuchGespeichertePruefungen = value.payload},
         setausbilderUnterschrift(state, value) {state.ausbilderUnterschrift = value.payload},
         setpopoverausbilderUnterschrift(state, value) {state.popoverausbilderUnterschrift = value.payload},
         setaktiveGK(state, value) {state.aktiveGK = value.value},
         setaktiveHK(state, value) {state.aktiveHK = value.value},
         setdopslidervalues(state, value) {state.dopslidervalues = value.payload},
         setkppvalues(state, value) {state.kppvalues = value.payload},
         updatesliderdop(state, value) {state.dopslidervalues[value.i].val = value.val},
         updatekpp(state, value) {state.kppvalues[value.i].val = value.val},
         updateNewImageFilePath(state, value) {state.pathNewUploadedImage = value.value},

           
           
           
     
                  
        setbreadcrumbItems(state, payload) {
         if (Array.isArray(payload.payload)) {
            state.breadcrumbItems = payload.payload
           }
         else {
            state.breadcrumbItems = [payload.payload]
         }
         },
         
         addbreadcrumbItems(state, payload) { state.breadcrumbItems = state.breadcrumbItems.splice(0,payload.reduceTo).concat(payload.routeObject)},
         reducebreadcrumbItems(state, payload) { state.breadcrumbItems = state.breadcrumbItems.splice(0,payload.reduceTo)},         
         
        
        
     },
     
     actions: {
     
      UpateStoreFromAPI({ commit }) {
      
        axios.post(config.$api_url_inhalte, {
          
             funktion: "returnState",
           }, {
           headers: {
               'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then(function (response) {
        var stateObject = response.data;
        commit('setEPAs', { payload: stateObject.epas });
        commit('setGKs', { payload: stateObject.gks });
        commit('setHKs', { payload: stateObject.hks });
        commit('setSMPs', { payload: stateObject.smps });
        commit('sethksInhalte', { payload: stateObject.hksInhalte });
        commit('setBereiche', { payload: stateObject.bereiche });


        })
        .catch(function (error) {
           console.log(error);
        });
        },
        
        
        UpatePruefungenFromAPI({ commit }) {
       let vthis = this; //in Verschachtelten Funktionen vthis statt this! https://www.techalyst.com/posts/vuejs-this-is-undefined-error-in-javascript-array-filter-and-functions
            
        
        axios.post(config.$api_url_arbeitsdaten, {
             funktion: "getPruefungen",
             userid: vthis.state.aktiverNutzer.id
          },{
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
        })
        .then(function (response) {
        var stateObject = response.data;
        commit('setvomNutzerimLogbuchGespeichertePruefungen', { payload: stateObject.pruefungen });

        })
        .catch(function (error) {
           console.log(error);
        });
        
        
        
        
        
        
        },
        
         UpateUnterschriftPendantListeFromAPI({ commit }) {
       let vthis = this; //in Verschachtelten Funktionen vthis statt this! https://www.techalyst.com/posts/vuejs-this-is-undefined-error-in-javascript-array-filter-and-functions

        axios.post(config.$api_url_auth, {
             funktion: "getUnterschriftPendantListe",
             lehrort: vthis.state.aktiverNutzer.lehrort,
             rolle: vthis.state.aktiverNutzer.rolle
        },{
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
        .then(function (response) {

        var stateObject = response.data;
		const updatedstateObject = stateObject.UnterschriftPendantListe.map((element) => ({
  		...element,
  		FullName: element.vorname + "_" + element.nachname
		}));        //Filtern auf Autrocomplete ermöglichen
  		
        commit('setUnterschriftPendantListe',  updatedstateObject );


        })
        .catch(function (error) {
           console.log(error);
        });
        
     return [];
    },
        
        
     UpdateAdminListFromAPI({ commit }) {
 let vthis = this; //in Verschachtelten Funktionen vthis statt this! https://www.techalyst.com/posts/vuejs-this-is-undefined-error-in-javascript-array-filter-and-functions
axios.post(config.$api_url_auth, {
            funktion: "adminList",
            user_mail: vthis.state.aktiverNutzer.email,
            user_lehrort: vthis.state.aktiverNutzer.lehrort,
            user_rolle: vthis.state.aktiverNutzer.rolle,


         },{
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
        })
        
        .then(function (response) {
        //vthis.adminListData = response.data.AdminList;
            var stateObject = response.data;
            commit('setadminList',{ payload: stateObject.AdminList })
        
        })
        .catch(function (error) {
           console.log(error);
        });
      
      
      
        
        return [];
     
     },

        },

})
