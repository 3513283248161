

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="tiny"
    version="1.0"
    viewBox="0 0 400 120"
    :width="width"
    :height="width"
    :reached="reached"
    :limit="limit"
  >
  
  <pattern id="simple-dots" x="0" y="0" width="6" height="6" patternUnits="userSpaceOnUse">
        <circle fill="#ee7b06" cx="2" cy="2" r="2" />
    </pattern>
    
    <pattern id="simple-stripes" patternUnits="userSpaceOnUse" width="9.5" height="9.5" patternTransform="rotate(45)">
			<line x1="0" y="0" x2="0" y2="9.5" stroke="#ee7b06" stroke-width="7" />
		</pattern>
		
		
	<text class="desc1" x="145" y="65" width="40" height="50" >Kompetenzstufe {{getSteps[reached-1].bezeichnung}}:</text>
    <text class="desc2" x="10" y="80" width="350" height="50" >{{getSteps[reached-1].beschreibung1}}</text>
    <text class="desc2" x="10" y="90" width="350" height="50" >{{getSteps[reached-1].beschreibung2}}</text>
    
    <rect
      :class="limit <= 1 ? 'steplimit showTextUnderBar' : reached >= 1 ? 'stepgreen showTextUnderBar' : 'stepgrey showTextUnderBar'"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="50" y="33" width="100" height="15"
    />
      <g class="showGroup">
    <rect x="10" y="49" width="500" height="50" fill="white" />
    <text class="desc1 " x="145" y="65" width="40" height="50" >Kompetenzstufe 1:</text>
    <text class="desc2 " x="10" y="80" width="350" height="50" >Sie dürfen beobachten, aber APT am Patienten noch nicht anwenden. </text>
    <text class="desc2 " x="10" y="90" width="350" height="50" ></text>
    </g>
    
    //2a

    <rect
      :class="limit < 2 ? 'teillimit showTextUnderBar' :reached >= 2 ? 'teilgreen showTextUnderBar' : 'teilgrey showTextUnderBar'"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="150" y="18" width="50" height="30"
    />
    
         <g class="showGroup">
    <rect x="10" y="49" width="500" height="50" fill="white" />
    <text class="desc1 " x="145" y="65" width="40" height="50" >Kompetenzstufe 2a:</text>
    <text class="desc2 " x="10" y="80" width="350" height="50" >{{limit &lt; 2 ? 'APT nicht auf Stufe 2a  anvertraubar' : 'Sie dürfen APT unter direkter Supervision (im Raum) durchführen'}} </text>
    <text class="desc2 " x="10" y="90" width="350" height="50" >{{limit &lt; 2 ? '' : 'und gemeinsam mit einer Ärztin/einem Arzt ausführen.'}}</text>
    </g>
    
    //2b
    
    <rect
      :class="limit < 3 ? 'teillimit showTextUnderBar' :reached >= 3 ? 'teilgreen showTextUnderBar' : 'teilgrey showTextUnderBar'"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="200" y="18" width="50" height="30"
    />
     <g class="showGroup">
    <rect x="10" y="49" width="500" height="50" fill="white" />
    <text class="desc1 " x="145" y="65" width="40" height="50" >Kompetenzstufe 2b:</text>
    <text class="desc2 " x="10" y="80" width="350" height="50" >{{limit &lt; 3 ? 'APT nicht auf Stufe 2b  anvertraubar' : 'Sie dürfen APT unter direkter Supervision (im Raum) durchführen'}} </text>
    <text class="desc2 " x="10" y="90" width="350" height="50" >{{limit &lt; 3 ? '' : 'und unter Beobachtung einer Ärztin/eines Arztes ausführen.'}}</text>
    </g>
    
    //2
    <rect
      class="outline"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="150" y="18" width="100" height="30"
    />
   
    
    //3a
     <rect
      :class="limit < 4 ? 'teillimit showTextUnderBar' :reached >= 4 ? 'teilgreen showTextUnderBar' : 'teilgrey showTextUnderBar'"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="250" y="3" width="34" height="45"
    />
     <g class="showGroup">
    <rect x="10" y="49" width="500" height="50" fill="white" />
    <text class="desc1 " x="145" y="65" width="40" height="50" >Kompetenzstufe 3a:</text>
    <text class="desc2 " x="10" y="80" width="350" height="50" >{{limit &lt; 4 ? 'APT nicht auf Stufe 3a  anvertraubar' : 'Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen'}} </text>
    <text class="desc2 " x="10" y="90" width="350" height="50" >{{limit &lt; 4 ? '' : 'und eigenständig ausführen, wird umfassend nachgeprüft.'}}</text>
    
    </g>
    
    //3b
     <rect
      :class="limit < 5 ? 'teillimit showTextUnderBar' :reached >= 5 ? 'teilgreen showTextUnderBar' : 'teilgrey showTextUnderBar'"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="283" y="3" width="34" height="45"
    />
    
     <g class="showGroup">
    <rect x="10" y="49" width="500" height="50" fill="white" />
    <text class="desc1 " x="145" y="65" width="40" height="50" >Kompetenzstufe 3b:</text>
    <text class="desc2 " x="10" y="80" width="350" height="50" >{{limit &lt; 5 ? 'APT nicht auf Stufe 3b  anvertraubar' : 'Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen'}} </text>
    <text class="desc2 " x="10" y="90" width="350" height="50" >{{limit &lt; 5 ? '' : 'und eigenständig ausführen und Wichtiges wird nachgeprüft.'}}</text>
    
    </g>
    
    //3c
     <rect
      :class="limit < 6 ? 'teillimit showTextUnderBar' : reached >= 6 ? 'teilgreen showTextUnderBar' : 'teilgrey showTextUnderBar'"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="316" y="3" width="34" height="45"
    />
   <g class="showGroup">
    <rect x="10" y="49" width="500" height="50" fill="white" />
    <text class="desc1 " x="145" y="65" width="40" height="50" >Kompetenzstufe 3c:</text>
    <text class="desc2 " x="10" y="80" width="350" height="50" >{{limit &lt; 6 ? 'APT nicht auf Stufe 3c anvertraubar' : 'Sie dürfen APT unter indirekter Supervision (Arzt/Ärztin auf Abruf) durchführen und eigen-'}} </text>
    <text class="desc2 " x="10" y="90" width="350" height="50" >{{limit &lt; 6 ? '' : 'ständig ausführen und Wichtiges wird durchgesprochen und ggf. punktuell nachgeprüft.'}}</text>
    </g>
    //3
    <rect
      class="outline"
      :stroke-width="strokeWidth"
      stroke-miterlimit="10"
      x="250" y="3" width="100" height="45"
    />
   

  </svg>
  
</template>

<script>
import { mapGetters } from 'vuex';


export default {


  name: 'Steps',
   computed: mapGetters([
        'getSteps',
    ]),
    
  props: {
    color: {
      type: String,
      default: 'grey', 
      required: false,
    },
    strokeWidth: {
      type: Number,
      default: 2,
      required: false,
    },
    width: {
      type: Number,
      default: 50,
      required: false,
    },
    reached: {
      type: Number,
      default: 0,
      required: false,
    },
    limit: {
      type: Number,
      default: 0, 
      required: false,
    }
  },
  
  

  
  
  
}




</script>

<style lang="css" scoped>
.grey {
  fill: #a99d8f;
  stroke: #575757;
}

.stepgreen{
  fill: #8abe24;
  stroke: #575757;
}

.stepgrey{
  fill: #fff;
  stroke: #575757;
}

.teilgreen {
fill: #8abe24;
}

.teilgrey {
fill: #fff;
}

.steplimit {
 fill: #a99d8f;
 
}

.teillimit {
   fill: #a99d8f;

}

.outline {
 stroke: #575757;
 fill: none;
}

.desc1 {
 font-family: "Roboto";
  font-weight: 500;
  font-size: 0.65rem;
  letter-spacing: .0333333333em;
  word-wrap: break-word;
  text-align: center;
}

.desc2 {
 font-family: "Roboto";
  font-weight: 300;
  font-size: 0.55rem;
  letter-spacing: .0333333333em;
  word-wrap: break-word;
  text-align: center;
}




.showTextUnderBar:hover + .showGroup{
   display:inline-block;
}

.showTextUnderBar:hover {
fill: #004a92;
  
}

.showGroup{
     display:none;
}



</style>
