import Vue from 'vue'
import VueRouter from 'vue-router'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)



//import { TokenService } from '../services/storage.service'

Vue.use(VueRouter)

const routes = [

  {
      path: '/',
      redirect: '/Heute'
    },

  
  
    {path: '/Epas',name: 'Epas',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Epas.vue')
      },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true
      },     },
      
      {path: '/Bereich',name: 'Bereich',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Bereich.vue')
      },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true
      },     },
       {path: '/EPA',name: 'EPA',props: true, 
     component: function () {
      return import(/* webpackChunkName: "about" */ '../views/EPA.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }}, 
     {path: '/SMP',name: 'SMP',
      component: function () {
      return import(/* webpackChunkName: "about" */ '../views/SMP.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }},

      
          {path: '/Unterschriftenliste',name: 'Unterschriftenliste',
      component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Unterschriftenliste.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }},
     
      {path: '/Benutzerverwaltung',name: 'Benutzerverwaltung',
      component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Benutzerverwaltung.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }},
 
 
                   
       {path: '/Impressum',name: 'Impressum',
      component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Impressum.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }},
     
     
      {path: '/Quellen',name: 'Quellen',
      component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Quellen.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }},
 
 
 
 
 
 
             
       {path: '/Bereiche',name: 'Bereiche',
      component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Bereiche.vue')
     },meta: {
     public: false,  // Allow access to even if not logged in
     scrollToTop: true      }},
 
      
      
            
     
     
          
      
      {path: '/GKNachschlagen',name: 'GKNachschlagen',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/GKNachschlagen.vue')
     },meta: {
        scrollToTop: true,
        public: false,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }},
      
      {path: '/GKEDIT',name: 'GKEDIT',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/GKEDIT.vue')
     },meta: {
        scrollToTop: true,
        public: false,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }},
       
      
       
      {path: '/HKNachschlagen',name: 'HKNachschlagen',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HKNachschlagen.vue')
     },meta: {
        scrollToTop: true,
        public: false,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }},
      
      {path: '/HKEDIT',name: 'HKEDIT',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HKEDIT.vue')
     },meta: {
        scrollToTop: true,
        public: false,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }},
       
      
      
      
      
      
      
      
        
    {path: '/Profil',name: 'Profil',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Profil.vue')
     },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true      }},
    
    
      
      
      
      
      
    {path: '/Heute',name: 'Heute',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Heute.vue')
     },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true      }},
    
    
    
    
    
    {path: '/Suche',name: 'Suche',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Suche.vue')
     },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true      }},
    
    
    
     {path: '/WB',name: 'WB',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/WB.vue')
     },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true      }},
    
    
    {path: '/AdminDashboard',name: 'AdminDashboard',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AdminDashboard.vue')
     },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true      }},
    
    
        
        
        
     {path: '/HKDoku',name: 'HKDoku',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HKDoku.vue')
     },meta: {
        public: false,  // Allow access to even if not logged in
        scrollToTop: true      }},
        
         {path: '/:pathMatch(.*)*', 
      name: 'NotFound',component: function () {
      return import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
     }
    }
        
        
        
     
  
]






const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes,
  
})


 
//  // const isPublic = to.matched.some(record => record.meta.public)
//   //const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
//   //const loggedIn = !!TokenService.getToken();
 // if (!isPublic && !this.$auth.isAuthenticated) {
//     return next({
//       path:'/login',
//       query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
//     });
//   }
 

//   // Do not allow user to visit login page or register page if they are logged in
//   //if (this.$auth.isAuthenticated) {
//     return next('/')
  
// })


export default router;