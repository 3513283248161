<template>
  <v-app>
    <v-main>
       
      <span> <AppBarTop/></span>
      
      
      

  <v-sheet
        id="scrolling-techniques-3"
        class="overflow-y-auto"
        max-height="94vh"
        flat
      >
      
 
<div v-if="!getAuthentifikation && currentRouteName != 'Impressum' "> <Login mode="Login"/> </div>
      
    <!-- Check that the SDK client is not currently loading before accessing is methods -->
   
      <!-- show login when not authenticated -->
      <div v-if="getAuthentifikation || this.currentRouteName === 'Impressum' " width="99vw">
      <!-- 
<span><div class="text-center">   
<p class="text-h6 text-center font-weight-light">Willkommen zum digitalen Logbuch für die Ausbildung im Praktischen Jahr!</p>
<p class="text-subtitle-2 text-center font-weight-light">Zur Nutzung der App bitte Einloggen.</p><v-btn x-large center color="primary" @click="handleLogin()"> Login </v-btn></div></span>
 -->

       <router-view></router-view>

    
      </div>

    
    <v-container style="height: 90px;" flat></v-container>
      </v-sheet>
 

        <span v-if="getAuthentifikation"><AppBarBottom/></span>


    </v-main>
  </v-app>
</template>

<script>

import AppBarTop from './components/AppBarTop';
import AppBarBottom from './components/AppBarBottom';
import Login from './components/Login';

import { mapGetters } from 'vuex';


     

export default {
  name: 'App',
  metaInfo: {
    title: 'Weiterbildungslogbuch Anästhesie',   
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'apple-mobile-web-app-capable', content: 'yes'},
      { name: 'apple-mobile-web-app-status-bar-style', content: 'black-translucent'},
    ]
  },
  

  components: {
    AppBarTop,
    AppBarBottom,
    Login

  },
 computed: {
    
    ...mapGetters([
 'loggedIn', 'getAktivesTertial', 'getAuthentifikation'
        ]),
       
       currentRouteName() {
        return this.$route.name;
        
        
    }
    },

  beforeCreate() {
  this.$store.dispatch('UpateStoreFromAPI');
  document.title = 'WBLogbuch'
 
  },

  
  
};
</script>


<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
@import 'vue2-dropzone/dist/vue2Dropzone.min.css'

html, body {
    height: 100%;
    overflow: hidden;
}

#main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

.v-application {
  background-color: #385f73;
}


</style>


