let config;

 {


 if (window.location.href.indexOf("wblb-test") > -1) {
 config = {
    $api_axios_base_url: "https://wblb-test.uke.de/",
    timeoutDuration: 30000,
    $api_url_auth: "https://wblb-test.uke.de/wblb-auth.php",
    $api_url_arbeitsdaten: "https://wblb-test.uke.de/wblb-arbeitsdaten.php",
    $api_url_inhalte: "https://wblb-test.uke.de/wblb-inhalte.php",
    $api_url_upload: "https://wblb-test.uke.de/img_api.php",
    $img_url: "https://wblb-test.uke.de/",

    
  };
 }
 
  else if (window.location.href.indexOf("wblogbuch.uke") > -1) {
 config = {
    $api_axios_base_url: "https://wblogbuch.uke.de/",
    timeoutDuration: 30000,
    $api_url_auth: "https://wblogbuch.uke.de/wblb-auth.php",
    $api_url_arbeitsdaten: "https://wblogbuch.uke.de/wblb-arbeitsdaten.php",
    $api_url_inhalte: "https://wblogbuch.uke.de/wblb-inhalte.php",
    $api_url_upload: "https://wblogbuch.uke.de/img_api.php",
    $img_url: "https://wblogbuch.uke.de/",
  };
 }
 
 
 else if (window.location.href.indexOf("wblogbuch.de") > -1) {
 config = {
    $api_axios_base_url: "https://wblogbuch.de/",
    timeoutDuration: 30000,
    $api_url_auth: "https://wblogbuch.de/wblb-auth.php",
    $api_url_arbeitsdaten: "https://wblogbuch.de/wblb-arbeitsdaten.php",
    $api_url_inhalte: "https://wblogbuch.de/wblb-inhalte.php",
    $api_url_upload: "https://wblogbuch.de/img_api.php",
    $img_url: "https://wblogbuch.de/",
  };
 }
 
 else if (window.location.href.indexOf("localhost:8080") > -1) {
 alert("Testversion LOKAL")
  config = {
    $api_axios_base_url: "http://localhost:31415",
    timeoutDuration: 30000,
    $api_url_auth: "http://localhost:31415/wblb-auth.php",
    $api_url_arbeitsdaten: "http://localhost:31415/wblb-arbeitsdaten.php",
    $api_url_inhalte: "http://localhost:31415/wblb-inhalte.php",
    $api_url_upload: "http://localhost:31415/img_api.php",
    $img_url: "http://localhost:31415/",
  };
 }
 
else if (window.location.href.indexOf("localhost:31415") > -1) {
alert("Testversion Build. Daten Lokal")
  config = {
    $api_axios_base_url: "http://localhost:31415",
    timeoutDuration: 30000,
    $api_url_auth: "http://localhost:31415/wblb-auth.php",
    $api_url_arbeitsdaten: "http://localhost:31415/wblb-arbeitsdaten.php",
    $api_url_inhalte: "http://localhost:31415/wblb-inhalte.php",
    $api_url_upload: "http://localhost:31415/img_api.php",
    $img_url: "/",
  };
 }

 else {
 alert("Aufruf der Seite außerhalb der freigegebenen Umgebung. Bitte Info mit Screenshot an d.baumann@uke.de falls Fehler unerwartet auftritt.");
 }
 
 }

export { config }