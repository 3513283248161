import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		light: true,
		themes: {
			light: {
				primary: '#385f73',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#b71c1c',
				gk: '#ADC8D7',
				hk: '#5187A4',
				gkbg: '#E6E8C4'},
},
icons: {
    iconfont: 'faSvg',
  },
},
});
