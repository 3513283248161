<template>
   <v-bottom-navigation
      color="primary" fixed
    >

     <v-btn :to="{name: 'Heute'}">
        <span>Übersicht</span>
  
        <v-icon>mdi-book</v-icon>
      </v-btn>


      <v-btn :to="{name: 'Epas'}">
        <span>EPAs</span>
  
        <v-icon>mdi-chart-donut</v-icon>
      </v-btn>
  
     
  <!-- 

   <v-btn :to="{name: 'WB'}" >
        <span>Dokumentation</span>
  
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
 -->

       <v-btn :to="{name: 'Suche'}" >
        <span>Suche</span>
  
        <v-icon>mdi-clipboard-search-outline</v-icon>
      </v-btn>

 
     
    </v-bottom-navigation>
</template>

<script>


  export default {
    name: 'AppBarBottom',
  }
</script>
